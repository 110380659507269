import React from 'react'
import { graphql, PageProps } from 'gatsby'

import { Layout, SEO, TopImageBox } from '../components/organisms/common'
import { Profile, Skills } from '../components/organisms/about'

type Props = {
  data: Queries.SkillsQuery
} & PageProps

const About: React.FC<Props> = (props) => {
  const { data } = props

  return (
    <Layout path="/about/">
      <TopImageBox filename="about.jpeg" alt="about top image" title="About" />
      <Profile />
      <Skills data={data} />
    </Layout>
  )
}

export default About

export const Head = () => <SEO />

export const pageQuery = graphql`
  query Skills {
    allContentfulSkill {
      edges {
        node {
          category
          name
          description
          tags
          icon {
            id
          }
        }
      }
    }
  }
`
