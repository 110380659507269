import React, { useState } from 'react'

import { DynamicImage, Button } from '../../atoms'
import * as styles from './skill-card.module.scss'

type Props = {
  imgName: string
  skillName: string
  skillTag: string
  skillDescription: string
  className?: string
}

export const SkillCard: React.FC<Props> = (props) => {
  const { skillName, skillTag, skillDescription, imgName, className } = props
  const [isClicked, setIsClicked] = useState(false)
  const toggleCard = () => setIsClicked((isClicked) => !isClicked)

  return (
    <Button
      isClicked={isClicked}
      onClick={toggleCard}
      className={`${styles.skillCard} ${className}`}
    >
      {!isClicked && (
        <DynamicImage filename={imgName} width={60} height={60} className={styles.img} />
      )}
      <div className={styles.cardContainer}>
        <h3>{skillName}</h3>
        {isClicked ? (
          <p className={styles.description}>{skillDescription}</p>
        ) : (
          <p className={styles.tag}>{skillTag}</p>
        )}
      </div>
    </Button>
  )
}

export default SkillCard
