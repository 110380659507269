import React from 'react'
import cx from 'classnames'

import { Text } from '../../atoms'
import SkillCard from './skill-card'
import * as styles from './skills.module.scss'

const skillCategory = [
  {
    title: '言語',
    category: 'language',
  },
  {
    title: 'フレームワーク',
    category: 'framework',
  },
  {
    title: 'データベース',
    category: 'database',
  },
  {
    title: 'その他',
    category: 'others',
  },
]

type Props = {
  data: Queries.SkillsQuery
}

const Skills: React.FC<Props> = (props) => {
  const { data } = props
  const allSkills = data.allContentfulSkill.edges

  const color = 'var(--second-color)'

  return (
    <section className={styles.skillsSection}>
      <Text as="h2" textType="subTitle" color={color}>
        SKILLS
      </Text>
      {skillCategory.map((v, i) => (
        <div key={v.category} className={cx(styles.skillGroup, i % 2 === 1 && styles.subColor)}>
          <Text as="h3" textType="engrave" className={styles.groupTitle}>
            {v.title}
          </Text>
          <div className={styles.skillsContainer}>
            {allSkills
              .filter((skill) => skill.node.category === v.category)
              .map((skill) => (
                <SkillCard
                  key={skill.node.name}
                  className={cx(styles.skillCard, i % 2 === 1 && styles.subColor)}
                  imgName={skill.node.icon?.id || 'no-img'}
                  skillName={skill.node.name || ''}
                  skillTag={skill.node.tags?.join(', ') || ''}
                  skillDescription={skill.node.description || ''}
                />
              ))}
          </div>
        </div>
      ))}
    </section>
  )
}

export default Skills
