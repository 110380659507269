import React from 'react'
import cx from 'classnames'

import { Text } from '../../atoms'
import { HistoryLine, PhotoFrame } from '../../molecules'
import * as styles from './profile.module.scss'

const Profile: React.FC = () => {
  return (
    <section className={styles.profileSection}>
      <div className={cx(styles.inner, 'container')}>
        <Text as="h2" textType="subTitle">
          PROFILE
        </Text>
        <div className={styles.profileContainer}>
          <div className={styles.innerLeft}>
            <PhotoFrame
              animationStart={true}
              size="s"
              filename="/chatran.jpg"
              width={160}
              height={160}
              alt="author picture"
              img
            />
            <Text as="p" className={styles.description}>
              京都市内にてフリーランスエンジニアとして活動しています。
              <br />
              <br />
              世に出た新しい技術を触ってみることが好きです。
              <br />
              <br />
              最近はブロックチェーンにハマっています。
            </Text>
          </div>
          <div className={styles.innerRight}>
            <HistoryLine title="Information">
              <dl className={styles.information}>
                <dt>名前</dt>
                <dd>高橋 満</dd>
                <dt>所在地</dt>
                <dd>京都市内</dd>
                <dt>形態</dt>
                <dd>フリーランス</dd>
                <dt>資格</dt>
                <dd>応用情報技術者</dd>
                <dt>趣味</dt>
                <dd>ゲーム、ランニング、動画鑑賞、アクアリウム、bot開発</dd>
              </dl>
            </HistoryLine>
            <HistoryLine title="History">
              <dl className={styles.history}>
                <dt>2017年</dt>
                <dd>東北大学 理学部 物理学科卒業</dd>
                <dt>2019年</dt>
                <dd>
                  東北大学大学院 理学部 物理学科卒業
                  <br />
                  <a
                    className={styles.link}
                    href="https://doi.org/10.1038/s41598-017-12187-9"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    2017. <i>Scientific Reports</i> <b>7</b> 12053
                  </a>
                  <br />
                  <a
                    className={styles.link}
                    href="https://drive.google.com/file/d/1gS5No_893KzPBcqle8mAX5iiBy7FPhLO/view?usp=sharing"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    2019. 修士論文
                  </a>
                  <br />
                  <a
                    className={styles.link}
                    href="https://doi.org/10.7566/JPSJ.89.094704"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    2020. <i>J. Phys. Soc. Jpn.</i> <b>89</b> 094704
                  </a>
                </dd>
                <dt>2019年</dt>
                <dd>株式会社 日立製作所入社</dd>
                <dt>2020年</dt>
                <dd>フリーランスとして独立</dd>
              </dl>
            </HistoryLine>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Profile
